import { IIntervalService } from 'angular';
import { DataService } from './ft.app.services.data';

export type Action = () => void;
type HandlerAction = (tag: string) => void;

export class RFIDService {
    public static $inject = ['$interval', 'ftAppDataService'];

    private readonly handlers: HandlerAction[] = [];
    private readonly dataService: DataService;

    constructor($interval: IIntervalService, dataService: DataService) {
        this.dataService = dataService;

        if ((window as any).ipcRenderer != null) {
            (window as any).ipcRenderer.on('tag', (_event, rfid) => this.tagReceived(rfid));
        } else {
            $interval(this.handlecheckRFID.bind(this), 1000);
        }
    }

    private async handlecheckRFID(): Promise<void> {
        const { response } = await this.dataService.checkRFID();
        this.tagReceived(response);
    }

    private tagReceived(tag: string): void {
        if (tag != null) {
            for (const handler of this.handlers) {
                handler(tag);
            }
        }
    }

    public onTag(handler: HandlerAction): Action {
        this.handlers.push(handler);

        return () => this.handlers.splice(this.handlers.indexOf(handler), 1);
    }
}

window.ft.app.module.service('rfidService', RFIDService);