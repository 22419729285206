import { IModule, IHttpPromise, IRootScopeService, IPromise } from 'angular';

declare module 'angular' {
    interface IModule {
        animation: (element: string, options: any) => void;
        settings: FortyTwoFrameworkSettings;
        levels: any;
        events: any;
        navigation: any;
        exceptions: any;
        handleRequestError: (response: IHttpResponse<any>) => IPromise<never>;
    }
}

window.ft = window.FortyTwo;
window.ng = window.angular;

export interface FTGrouping<T> {
    title: string;
    original: string;
    items: T[];
}

export interface FTGroup<T = any> {
    items: T[];
    count: number;
    checked: boolean;
    partialChecked: boolean;
}

export interface AppModule extends IModule {
    getTemplateUrl: (urlPart: string) => string;
    models: any;
    baseUrl: string;
    baseTemplateUrl: string;
    baseApiUrl: string;
    customer: string;
    baseLocalUrl: string;
}

export interface FortyTwoStatic {
    randomNumber: number;
    DEBUG: boolean;
    isMobile: boolean;
    app: { module: AppModule };
    models: FortyTwoModels;
    framework: FortyTwoFramework;

    appEvents: {
        isLoading: 'is-loading';
        tokenRefresh: 'token-refresh';
        enableRFID: 'enable-rfid';
        userSignedIn: 'user-signed-in';
        renewToken: 'renew-token';
    };

    userTypes: {
        child: 'child';
        employee: 'empoyee';
        parent: 'parent';
    };

    log: (level: string, ...args: Array<any>) => void;
    createDelegate: (ctx: any, fnc: any) => Function;
    defineNamespace: (namespace: string) => any;
    defineBindableProperty: (obj: any, prop: any, getter: any, setter?: any) => void;
    dontPropagate: (evt: any) => void;
    getRandom: (min: number, max: number) => number;
    getInterval: (min: number, max: number) => number;
    getQueryParam: (name: string) => string;
    removeQueryParam: (name: string, href: string) => string;
    hasValue: (val: any) => boolean;
}

export interface FortyTwoRootScopeService extends IRootScopeService {
    showSplash: boolean;
    configuration: any;
}

export interface FortyTwoFramework {
    module: IModule;
}

export interface FortyTwoFrameworkSettings {
    datetime: any;
    keys: any;
    sorting: any;
}

export interface TokenData {
    refreshToken: string;
    token: {
        expires: string;
        value: string;
    };
}

export interface FortyTwoLoginService {
    storeTokenData(tokenResponse: any);
    doLogout(): void;
    getCurrentUserAsync(): IPromise<any>;
    isLoggedInAsync(): IPromise<boolean>;
    getStoredUsername(): string;
    wantsToStayLoggedIn(): boolean;
    hasValidTokenAsync(): IPromise<boolean>;
    hasRefreshTokenAsync(): IPromise<boolean>;
    doLoginAsync(username: string, password: string, stayLoggedIn: boolean): IPromise<boolean>;
    refreshLoginAsync(): IPromise<boolean>;
    getTokenData(): TokenData;
}

export interface FortyTwoModels {
    User: FortyTwoUser;
    Group: (options: any) => FortyTwoGroup;
}

export interface FortyTwoUser {
    hasRole: (role: string) => boolean;
    obj: (options: any) => any;
}

export interface FortyTwoGroup {
    getCount: () => number;
}

export interface FortyTwoRequestService {
    config: any;
    requestAsync: (
        request: FortyTwoRequest,
        useAuthorization?: boolean,
        ignoreContentType?: boolean,
        triggerEvents?: boolean
    ) => IHttpPromise<any>;
}

export interface FortyTwoRequest {
    url?: string;
    path: string;
    headers?: any;
    method?: 'POST' | 'GET' | 'PUT' | 'DELETE';
    data?: any;
}

// eslint-disable-next-line @typescript-eslint/class-name-casing
export interface ftSettingsService {
    remove(configuration: string);
    get(key: string);
    set(key: string, object: any);
}
