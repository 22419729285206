ft.defineNamespace('FortyTwo.app');
window.app = ft.app;

window.appKeys = {
    endpoint: 'endpoint',
    user: 'user',
    configuration: 'configuration'
};

ft.appEvents = {
    isLoading: 'is-loading',
    tokenRefresh: 'token-refresh',
    enableRFID: 'enable-rfid',
    userSignedIn: 'user-signed-in',
    renewToken: 'renew-token'
};

ft.userTypes = {
    child: 'child',
    employee: 'empoyee',
    parent: 'parent'
};
