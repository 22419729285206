ft.app.module.controller('tteNewsController',
    ['$scope', '$state', '$timeout', 'ftSettingService', 'ftLoginService',
        function ($scope, $state, $timeout, ftSettingService, ftLoginService) {

            var initialized = false;
            $scope.articles = null;
            $scope.today = null;

            var initialize = function () {

                if (initialized)
                    return;

                ftLoginService.isLoggedInAsync().then(null, function() {
                    $state.go('outside-app.initialize');
                });

                $scope.articles = $state.params.articles;
                $scope.config = ftSettingService.get(appKeys.configuration);
                $scope.today = moment();

                initialized = true;
            };

            $scope.goToHome = function () {
                $state.go('outside-app.home');
            };

            $scope.toHumanDate = function (date) {
                return moment(date).format('dddd LL');
            };

            $scope.isCurrentDate = function (date) {

                var articleDate = moment(date);
                // eslint-disable-next-line no-redeclare
                var date = moment();
                var dateDiff = date.diff(articleDate, 'days');
                if (dateDiff != 0) {
                    return false;
                }

                return true;
            };

            $scope.articlesToday = function () {
                if (!$scope.articles)
                    return;

                var date = moment();
                var count = 0;
                $scope.articles.forEach(function (article) {
                    var articleDate = moment(article.start);

                    var dateDiff = date.diff(articleDate, 'days');
                    if (dateDiff == 0) {
                        count++;
                    }
                });

                return count;
            };

            $scope.articlesNotToday = function () {
                if (!$scope.articles)
                    return;

                var date = moment();
                var count = 0;
                $scope.articles.forEach(function (article) {
                    var articleDate = moment(article.start);

                    var dateDiff = date.diff(articleDate, 'days');
                    if (dateDiff != 0) {
                        count++;
                    }
                });

                return count;
            };

            $scope.readMore = function (article) {
                article.opened = article.opened ? false : true;
            };

            $timeout(initialize);
        }
    ]);