import { FortyTwoLoginService, FortyTwoRequest, FortyTwoRequestService, ftSettingsService } from '@/FortyTwoFramework';
import { IPromise } from 'angular';
import { IStateService } from 'angular-ui-router';
import * as CryptoJS from 'crypto-js';
import moment from 'moment';

export type KioskMode = 'readOnly' | 'normal';
export type Gender = 'male' | 'female' | 'unknown';
export type PersonType = 'user' | 'child' | 'parent' | 'fetcher' | 'employee' | 'group';
export type MediaType = 'image' | 'video';

export interface RFIDResponse {
    id: string;
    firstName: string;
    lastName: string;
    lastNamePrefix: string;
    fullName: string;
    gender: Gender;
    type: PersonType;
    picture: MediaReference;
}

export interface MediaReference {
    mediaId: string;
    childId: string;
    type: string;
    poster: string;
    url: string;
    title: string;
}

export interface ActivitiesByDateResponse {
    activityPlannings: ActivityPlanning[];
}

export interface ActivityPlanning {
    activityPlanningId: string;
    name: string;
    description: string;
    start: string;
    end: string;
    allowEnrollment: boolean;
    activity: Activity;
    enrollmentCount: number;
    maximumGroupSize: number;
    place: string;
    isSeries: boolean;
    seriesId: string;
}

export interface Activity {
    activityId: string;
    name: string;
    poster: string;
    color: string;
    areaTypeId: string;
    parentActivityId: string;
    groupSize: number;
    description: string;
}

export class DataService {
    public static $inject = ['ftLoginService', 'ftRequest', 'ftSettingService', '$state'];

    private ftLoginService: FortyTwoLoginService;
    private ftRequest: FortyTwoRequestService;
    private ftSettingService: ftSettingsService;
    private $state: IStateService;

    constructor(ftLoginService: FortyTwoLoginService, ftRequest: FortyTwoRequestService, ftSettingService: ftSettingsService, $state: IStateService) {
        this.ftLoginService = ftLoginService;
        this.ftRequest = ftRequest;
        this.ftSettingService = ftSettingService;
        this.$state = $state;

        this.locationConfiguration = this.locationConfiguration.bind(this);
        this.checkRFID = this.checkRFID.bind(this);
        this.rfidLogin = this.rfidLogin.bind(this);
        this.renewToken = this.renewToken.bind(this);
        this.getUserByRFIDTag = this.getUserByRFIDTag.bind(this);
        this.getLocationByRFIDTag = this.getLocationByRFIDTag.bind(this);
        this.getAlbums = this.getAlbums.bind(this);
        this.getMediaAlbum = this.getMediaAlbum.bind(this);
        this.getLocationNews = this.getLocationNews.bind(this);
        this.getActivites = this.getActivites.bind(this);
        this.getChildEnrollments = this.getChildEnrollments.bind(this);
        this.enrollChild = this.enrollChild.bind(this);
        this.disenrollChild = this.disenrollChild.bind(this);
        this.getKioskMode = this.getKioskMode.bind(this);
    }

    public locationConfiguration() {
        return this.get({ path: 'configuration' }, false);
    }

    public checkRFID(): IPromise<{ response: string }> {
        return this.ftRequest.requestAsync({
            url: window.ft.app.module.baseLocalUrl,
            path: 'fetchRFID.php',
            method: 'POST',
            data: {
                type: 'fetch'
            }
        }).then(response => response.data);
    }

    public rfidLogin(rfid: string): IPromise<any> {
        const date = moment();

        const message = `${this.ftSettingService.get(window.appKeys.endpoint)}token/${rfid}`;
        const secret = `${date.format('YYYY')}-${rfid}-${date.format('MM')}-${date.format('DD')}`;

        const hash = CryptoJS.HmacSHA256(message, secret);
        const signature = CryptoJS.enc.Base64.stringify(hash);

        return this.get({
            path: 'token/' + rfid,
            headers: {
                'X-Signature': signature
            }
        }, false);
    }

    public renewToken(refreshToken: string): IPromise<any> {
        return this.doRequest({
            path: 'account/token',
            headers: {
                'Authorization': 'Bearer ' + refreshToken
            }
        }, false).then((token) => {
            this.ftLoginService.storeTokenData(token);
            return token;
        });
    }

    public getUserByRFIDTag(rfid: string): IPromise<RFIDResponse> {
        return this.get({ path: 'rfid/' + rfid }, true);
    }

    public getLocationByRFIDTag(rfid: string): IPromise<any> {
        return this.ftRequest.requestAsync({
            url: 'https://42.42windmills.com/',
            path: 'Services/Endpoints.aspx?rfid=' + rfid
        }).then(
            function (response) {
                return response.data;
            },
            function (response) {
                return window.ft.framework.module.handleRequestError(response);
            }
        );
    }

    public getAlbums(): IPromise<any> {
        return this.get({ path: 'albums?maxnumberofalbums=20' }, true);
    }

    public getMediaAlbum(albumId: string): IPromise<any> {
        return this.get({ path: 'album/' + albumId }, true);
    }

    public getLocationNews(): IPromise<any> {
        return this.get({ path: 'newsreleases' }, true);
    }

    public getActivites(date: moment.Moment): IPromise<ActivitiesByDateResponse> {
        return this.get({ path: 'activities/' + date.format('YYYY-MM-DD') }, true);
    }

    public getChildEnrollments(childId: string): IPromise<any> {
        return this.get({ path: 'enrollments/' + childId }, true);
    }

    public enrollChild(childId: string, activityPlanningId: string): IPromise<any> {
        return this.post({
            path: 'enroll',
            data: {
                activityPlanningId: activityPlanningId,
                childId: childId
            }
        }, true);
    }

    public disenrollChild(childId: string, activityPlanningId: string): IPromise<any> {
        return this.delete({
            path: 'disenroll',
            data: {
                activityPlanningId: activityPlanningId,
                childId: childId
            }
        }, true);
    }

    public getKioskMode(): IPromise<{ mode: KioskMode }> {
        return this.get({ path: 'kioskmode' }, true);
    }

    private delete(request: FortyTwoRequest, loggedIn: boolean): IPromise<any> {
        request.method = 'DELETE';

        if (loggedIn) {
            return this.doLoggedInRequest(request);
        } else {
            return this.doRequest(request);
        }
    }

    private get(request: FortyTwoRequest, loggedIn: boolean): IPromise<any> {
        request.method = 'GET';

        if (loggedIn) {
            return this.doLoggedInRequest(request);
        } else {
            return this.doRequest(request);
        }
    }

    private post(request: FortyTwoRequest, loggedIn: boolean): IPromise<any> {
        request.method = 'POST';

        if (loggedIn) {
            return this.doLoggedInRequest(request);
        } else {
            return this.doRequest(request);
        }
    }

    private doRequest(request: FortyTwoRequest, loggedIn?: boolean): IPromise<any> {
        request.url = this.ftSettingService.get(window.appKeys.endpoint);

        return this.ftRequest.requestAsync(request, loggedIn).then(
            function(response) {
                return response.data;
            },
            function(response) {
                return window.ft.framework.module.handleRequestError(response);
            }
        );
    }

    private doLoggedInRequest(request: FortyTwoRequest): IPromise<any> {
        return this.ftLoginService
                .isLoggedInAsync()
                .then(
                    () => this.doRequest(request, true), 
                    () => this.$state.go('outside-app.setup')
                );
    }
}

window.ft.app.module.service('ftAppDataService', DataService);
    