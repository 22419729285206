ft.app.module.controller('tteAlbumController',
	['$scope', '$state', 'ftLoginService', '$timeout', 'ftSettingService', '$filter', 'navigationService',
		function ($scope, $state, ftLoginService, $timeout, ftSettingService, $filter, navigationService) {

			var initialized = false;
			$scope.album = null;
			$scope.today = moment();
			$scope.config = null;
			$scope.albumOpened = false;

			let idleInterval;
			const lastAction = moment();

			var initialize = function () {
				if (initialized)
					return;

				ftLoginService.isLoggedInAsync().then(null, function () {
					$state.go('outside-app.initialize');
				});

				if ($state.params.album == null) {
					navigationService.navigateToHome();
				}

				$scope.config = ftSettingService.get(appKeys.configuration);
				addMediaTokens($state.params.album);

				$(document).ready(function () {
					lightGallery(document.getElementById('lightgallery'), {
						download: false,
						enableTouch: true,
						zoom: true,
						loop: false,
						closable: false,
						selector: 'a',
						thumbnail: true
					});

					var $customEvents = document.getElementById('lightgallery');
					lightGallery($customEvents);

					$customEvents.addEventListener('onBeforeOpen', function (event) {
						$scope.albumOpened = true;
					});

					$customEvents.addEventListener('onBeforeClose', function (event) {
						$scope.albumOpened = false;
					});

				});

				//Increment the idle time counter every minute.
				idleInterval = setInterval(() => {
					if (moment().diff(lastAction, 'minutes') >= 1) {
						navigateBack();
					}
				}, 1000);

				initialized = true;
			};

			var addMediaTokens = function (album) {
				var token = $filter('urlencode')(ftLoginService.getTokenData().token.value);

				album.items.forEach(function (mediaItem) {
					mediaItem.media.url = mediaItem.media.url + '?access_token=' + token;
					mediaItem.media.poster = mediaItem.media.poster + '?access_token=' + token;
				});

				$scope.album = album;
			};

			$scope.goToHome = function () {
				clearInterval($scope.idleInterval);
				$state.go('outside-app.home');
			};

			$scope.toHumanDate = function (date) {
				var splitted = date.split('T');

				var currentDate = splitted[0];
				var time = splitted[1].replace(/-/g, ':');
				var newDate = currentDate + 'T' + time;

				return moment(newDate).format('dddd LL');
			};


			$timeout(initialize);
		}
	]);