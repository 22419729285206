console.log(`🚀 Version ${env.buildnumber || '<no-build>'} build from ${env.commit || '<no-commit>'} and currently running in ${env.mode} mode`);

import moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');
window.moment.locale('nl');

import '@/update';
import '@public/css/app.less';

import 'angular-sanitize';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import 'lightgallery.js/dist/css/lightgallery.css';
import 'lightgallery.js/dist/css/lg-fb-comment-box.css';
import 'lightgallery.js/dist/css/lg-transitions.css';
import 'lightgallery.js';
import 'lg-thumbnail.js';
import 'lg-zoom.js';

import '@/FortyTwoFramework';

import '@/01.ft.app';
import '@/02.ft.app.module';
import '@/03.network-utils';

import '@/ft.app.filters';

import '@/services/index';
import '@/controllers/index';