window.ft.app.module.controller('tteInitializeController',
    ['$rootScope', '$scope', '$state', '$timeout', 'ftSettingService', 'ftAppDataService', 'ftLoginService',
        function ($rootScope, $scope, $state, $timeout, ftSettingService, DataService, ftLoginService) {

            var initialized = false;
            var prmRefreshToken, prmGetConfiguration;
            $scope.isLoading = false;
            $scope.pageStyle = null;

            var initialize = function () {

                if (initialized)
                    return;

                checkAccountState();
                initialized = true;

            };

            var checkAccountState = function () {
                if (ftLoginService.getTokenData().refreshToken) {
                    navigateToHome();
                } else {
                    setupForLocation();
                }
            };

            var navigateToHome = function () {
                renewToken();
            };

            // initialize the setup process
            var setupForLocation = function () {

                $timeout(function () {
                    $rootScope.$broadcast(window.ft.appEvents.isLoading, false);
                }, 1500);

                $state.go('outside-app.setup');
            };

            var renewToken = function () {
                if (prmRefreshToken) {
                    return;
                }

                var refreshToken = ftLoginService.getTokenData().refreshToken;
                prmRefreshToken = DataService.renewToken(refreshToken);
                prmRefreshToken.then(handleRenewToken, handleError);
                prmRefreshToken.finally(function () { $scope.isLoading = false; prmRefreshToken = null; });
            };

            var handleRenewToken = function (token) {
                ftLoginService.storeTokenData(token);
                getConfiguration();
            };

            var getConfiguration = function () {
                if (prmGetConfiguration)
                    return;

                prmGetConfiguration = DataService.locationConfiguration();
                prmGetConfiguration.then(handleGetConfiguration, handleError);
                prmGetConfiguration.finally(function () { $scope.isLoading = false; prmGetConfiguration = null; });
            };

            var handleGetConfiguration = function (result) {
                ftSettingService.set(window.appKeys.configuration, result);

                $timeout(function () {
                    $rootScope.$broadcast(window.ft.appEvents.isLoading, false);
                    $rootScope.$broadcast(window.ft.appEvents.tokenRefresh);
                    $state.go('outside-app.home');
                }, 1500);
            };

            var handleError = function (result) {
                console.log(result);
            };

            $timeout(initialize);

        }
    ]);
