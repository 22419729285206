import { FortyTwoLoginService, ftSettingsService } from '@/FortyTwoFramework';
import { IPromise } from 'angular';
import { IStateService } from 'angular-ui-router';
import { AppConfigurationService } from './app-configuration-service';

export type Action = () => void;

export class NavigationService {
    public static $inject = ['appConfigurationService', '$state', 'ftLoginService', 'ftSettingService'];
    private appConfigurationService: AppConfigurationService;
    private $state: IStateService;
    private loginService: FortyTwoLoginService;
    private ftSettingService: ftSettingsService;

    constructor(appConfigurationService: AppConfigurationService, $state: IStateService, loginService: FortyTwoLoginService, ftSettingService: ftSettingsService) {
        this.appConfigurationService = appConfigurationService;
        this.$state = $state;
        this.loginService = loginService;
        this.ftSettingService = ftSettingService;

        this.navigateToActivities = this.navigateToActivities.bind(this);
        this.navigateToAlbums = this.navigateToAlbums.bind(this);
        this.navigateToHome = this.navigateToHome.bind(this);
    }

    private checkLoginState(): IPromise<boolean> {
        return this.loginService.isLoggedInAsync().then(
            () => true,
            () => {
                this.$state.go('outside-app.initialize');
                return false;
            });
    }

    public async navigateToActivities(): Promise<void> {
        if (!await this.checkLoginState()) {
            return;
        }

        if (this.$state.current.name === 'outside-app.activities' || this.$state.current.name === 'outside-app.readonly-activities') {
            this.$state.reload();
        } else {
            const user = this.ftSettingService.get(window.appKeys.user);
            const mode = await this.appConfigurationService.getKioskMode();

            if (user != null && mode === 'normal') {
                this.$state.go('outside-app.activities');
            } else {
                this.$state.go('outside-app.readonly-activities');
            }
        }
    }

    public async navigateToAlbums(): Promise<void> {
        if (!await this.checkLoginState()) {
            return;
        }

        this.$state.go('outside-app.album');
    }

    public async navigateToHome(): Promise<void> {
        if (!await this.checkLoginState()) {
            return;
        }

        this.ftSettingService.set(window.appKeys.user, null);
        this.$state.go('outside-app.home');
    }
}

window.ft.app.module.service('navigationService', NavigationService);