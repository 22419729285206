import moment from 'moment';

window.ft.app.module.controller('tteHome',
	['$rootScope', '$scope', '$state', '$stateParams', 'ftLoginService', '$timeout', 'ftAppDataService', 'ftSettingService', '$filter', 'navigationService',
		function ($rootScope, $scope, $state, $stateParams, ftLoginService, $timeout, DataService, ftSettingService, $filter, navigationService) {

			var current = $state.current;
			var initialized = false;
            var offDestroy;
            var prmGetAlbums, prmGetLocationNews, prmgetAlbumItems;

			$scope.isLoading = false;
			$scope.config = null;
			$scope.items = [];
			$scope.rijHtml = '<div class="rij">';
			$scope.rijEndHtml = '</div>';
			$scope.today = moment();
			$scope.items = [{
				type: 'activities'
			}];

			$scope.newItems = [];
			$scope.filteredItems = [];
			$scope.newsItems = null;
			$scope.selectedAlbum = null;

			var initializeEvents = function () {
				offDestroy = $scope.$on('$destroy', handleDestroy);
			};

			var handleDestroy = function () {
				if (offDestroy) offDestroy();
			};

			var initialize = function () {

				if (initialized)
					return;

				$rootScope.$broadcast(window.ft.appEvents.enableRFID);
				initializeEvents();

				$scope.config = ftSettingService.get(window.appKeys.configuration);
                $rootScope.$broadcast(window.ft.appEvents.isLoading, false);

				// only save current state after login (when in-app)
				if (current.name.indexOf('app') == 0)
					ftSettingService.set(window.ft.framework.module.settings.keys.currentState, [current.name, $stateParams]);

				$scope.pageStyle = current.pageStyle || null;
				getData();

				initialized = true;
			};

            var getData = function () {
				getLocationNews();
				getAlbums();
			};

            var getAlbums = function () {
                if (prmGetAlbums)
                    return;

				prmGetAlbums = DataService.getAlbums();
				prmGetAlbums.then(handleGetAlbums, handleError);
				prmGetAlbums.finally(function () { prmGetAlbums = null; });
			};

            var handleGetAlbums = function (result) {

				for (const item of result.items) {
					var token = encodeURIComponent(ftLoginService.getTokenData().token.value);

					var data = {
						content: item.title,
						type: 'image',
						poster: item.posterUrl + '?access_token=' + token,
						postedAt: moment(item.createdOn),
						id: item.id
					};

					$scope.items.push(data);
				}

				transformItems();
			};

            var getLocationNews = function () {
                if (prmGetLocationNews)
                    return;

				prmGetLocationNews = DataService.getLocationNews();
				prmGetLocationNews.then(handleGetLocationNews, handleError);
				prmGetLocationNews.finally(function () { prmGetLocationNews = null; });
			};

			var handleGetLocationNews = function (result) {
				$scope.newsItems = result.newsReleases;
			};

			var transformItems = function () {

				$scope.newItems = [];

				var i;
				for (i = 0; i < $scope.items.length; i++) {

					if (i % 2 !== 0)
						continue;

					var obj = {
						items: [$scope.items[i]]
					};

					obj.items[0].index = i;
					if ($scope.items[i + 1] !== undefined) {
						obj.items.push($scope.items[i + 1]);
						obj.items[1].index = i + 1;
					}

					$scope.newItems.push(obj);
				}

			};

			$scope.selectAlbum = function (album) {
				$scope.isLoading = true;
				$scope.loadingDescription = 'Een moment geduld aub...';
				$scope.selectedAlbum = album;
				getAlbumItems(album);
			};

			$scope.goToActivities = function () {
				navigationService.navigateToActivities();
            };

            $scope.loadNews = function () {
                $state.go('outside-app.news', { articles: $scope.newsItems });
            };

            var getAlbumItems = function (album) {
                if (prmgetAlbumItems)
                    return;

				prmgetAlbumItems = DataService.getMediaAlbum(album.id);
				prmgetAlbumItems.then(handleGetAlbumItems, handleError);
				prmgetAlbumItems.finally(function () { prmgetAlbumItems = null; });
			};

			var handleGetAlbumItems = function (result) {
				$scope.isLoading = false;
				$scope.selectedAlbum.items = result.media;
				$state.go('outside-app.album', { album: $scope.selectedAlbum });
			};

			var previousItem = null;

			$scope.calculateWidth = function (item) {

				var additionalClasses = '';
				var width = item.type === 'video' ? 7 : 6;
				var nextItem = $scope.items[item.index + 1];
				if (nextItem !== undefined) {
					if (nextItem.type === 'video' && item.type === 'video')
						width = 6;

					if (nextItem.type === 'video' && item.type === 'image')
						width = 5;
				}

				if (previousItem && item.index % 2 !== 0) {
					width = 12 - previousItem;
				}

				if (width === 6 && item.type === 'image')
					additionalClasses = 'type-image';

				previousItem = width;
				return 'column width-' + width + ' ' + additionalClasses;

			};

            var handleError = function (result) {
                $state.go('outside-app.initialize');
			};

			$timeout(initialize);
		}
	]);
