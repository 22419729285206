import { RFIDService } from '@/services/rfid-service';
import { IScope, IRootScopeService, ITimeoutService } from 'angular';
import { IStateService } from 'angular-ui-router';
import { FortyTwoLoginService, ftSettingsService } from '@/FortyTwoFramework';
import { AppConfigurationService } from '@/services/app-configuration-service';

interface Scope extends IScope {
    isLoading: boolean;
}

window.ft.app.module.controller('tteSetupController',
    ['$rootScope', '$scope', '$state', '$timeout', 'ftAppDataService', 'ftSettingService', 'rfidService', 'ftLoginService',
        function ($rootScope: IRootScopeService, $scope: Scope, $state: IStateService, $timeout: ITimeoutService, dataService, ftSettingService: ftSettingsService, rfidSerivce: RFIDService, ftLoginService: FortyTwoLoginService) {
            $scope.isLoading = false;

            const initialize = () => {
                $timeout(() =>  $rootScope.$broadcast(window.ft.appEvents.isLoading, false), 1000 * 2);

                const unsubscribe = rfidSerivce.onTag(onTag);
                $scope.$on('$destroy', unsubscribe);
            };

            const onTag = async (rfid) => {
                if (rfid == null || $scope.isLoading) {
                    return;
                }

                $scope.isLoading = true;

                try {
                    const endpointResponse = await dataService.getLocationByRFIDTag(rfid);
                    if (endpointResponse.message != null) {
                        console.log(`Endpoint result for ${rfid}: ${endpointResponse.message}`);
                        return;
                    }

                    ftSettingService.set(window.appKeys.endpoint, endpointResponse.endpoint);

                    const tokenResponse = await dataService.rfidLogin(rfid);
                    ftLoginService.storeTokenData(tokenResponse);

                    const configResponse = await dataService.locationConfiguration();
                    ftSettingService.set(window.appKeys.configuration, configResponse);

                    $timeout(() => {
                        $state.go('outside-app.home');
                        $rootScope.$broadcast(window.ft.appEvents.tokenRefresh);
                        $rootScope.$broadcast(window.ft.appEvents.enableRFID);
                    });
                } catch (error) {
                    console.log(error);

                    ftLoginService.doLogout();
                    ftSettingService.remove(window.appKeys.configuration);
                } finally {
                    $scope.isLoading = false;
                    $scope.$apply();
                }
            };

            $timeout(initialize);
        }
    ]);