import { ftSettingsService } from '@/FortyTwoFramework';
import { ActivityPlanning, DataService, KioskMode, RFIDResponse } from '@/services/ft.app.services.data';
import { NavigationService } from '@/services/navigation-service';
import { IRootScopeService, IScope, ITimeoutService } from 'angular';
import moment from 'moment';

interface Scope extends IScope {
    activities: EnrollableActivityPlanning[];
    user: RFIDResponse;
    userTypes: typeof window.ft.userTypes;
    isLoading: boolean;
    today: moment.Moment;
    kioskMode: KioskMode;
    goToHome: () => void;
    toggleActivityPlanning: (activityPlanning: any) => void;
    enrollChild: (activityPlanning: any) => Promise<void>;
    disenrollChild: (activityPlanning: any) => Promise<void>;
}

interface EnrollableActivityPlanning extends ActivityPlanning {
    enrolled: boolean;
    opened: boolean;
}

window.ft.app.module.controller('tteActivitiesController',
    ['$rootScope', '$scope', '$timeout', 'ftAppDataService', 'navigationService', 'ftSettingService',
        function ($rootScope: IRootScopeService, $scope: Scope, $timeout: ITimeoutService, DataService: DataService, navigationService: NavigationService, ftSettingService: ftSettingsService) {
            $scope.user = ftSettingService.get(window.appKeys.user);
            $scope.userTypes = window.ft.userTypes;
            $scope.isLoading = false;
            $rootScope.$broadcast(window.ft.appEvents.isLoading, false);

            $scope.today = moment();

            let idleInterval: any;
            let lastAction = moment();
            const initialize = function () {
                loadActivities();

                //Increment the idle time counter every minute.
                idleInterval = setInterval(() => {
                    if (moment().diff(lastAction, 'minutes') >= 1) {
                        navigationService.navigateToHome();
                    }
                }, 1000);
            };

            $scope.$on('$destroy', () => {
                clearInterval(idleInterval);
            });

            const loadActivities = async function () {
                $scope.isLoading = true;

                try {
                    const response = await DataService.getActivites(moment());

                    if ($scope.user && $scope.user.type == window.ft.userTypes.child) {
                        const result = await DataService.getChildEnrollments($scope.user.id);

                        for (const activityPlanning of response.activityPlannings as EnrollableActivityPlanning[]) {
                            for (const enrollment of result.enrollments) {
                                if (activityPlanning.activityPlanningId == enrollment.activityPlanning.activityPlanningId) {
                                    if (!activityPlanning.enrolled)
                                        activityPlanning.enrolled = true;
                                }
                            }
                        }
                    }

                    $scope.activities = response.activityPlannings as EnrollableActivityPlanning[];
                } finally {
                    $scope.isLoading = false;
                    $scope.$apply();
                }
            };

            $scope.goToHome = navigationService.navigateToHome;

            $scope.toggleActivityPlanning = function (activityPlanning: EnrollableActivityPlanning) {
                lastAction = moment();
                activityPlanning.opened = activityPlanning.opened ? false : true;
            };

            $scope.enrollChild = async function (activityPlanning: EnrollableActivityPlanning) {
                $scope.isLoading = true;

                try {
                    await DataService.enrollChild($scope.user.id, activityPlanning.activityPlanningId);
                    $scope.goToHome();
                } finally {
                    $scope.isLoading = false;
                    $scope.$apply();
                }
            };

            $scope.disenrollChild = async function (activityPlanning: EnrollableActivityPlanning) {
                $scope.isLoading = true;

                try {
                    await DataService.disenrollChild($scope.user.id, activityPlanning.activityPlanningId);
                    $scope.goToHome();
                } finally {
                    $scope.isLoading = false;
                    $scope.$apply();
                }
            };

            $timeout(initialize);
        }]);
