ft.app.module = ng.module('ttScherm', ['ftComponents', 'ftLayout']);

ft.app.module.baseUrl = ft.DEBUG ? '' : '';
ft.app.module.baseTemplateUrl = 'partials/';
ft.app.module.baseApiUrl = '';
ft.app.module.baseLocalUrl = 'http://127.0.0.1/';

var storedEndpoint = window.localStorage.getItem('ngStorage-endpoint');
if (storedEndpoint != null) {
    ft.app.module.baseApiUrl = JSON.parse(storedEndpoint);
} else {
    if (ft.getQueryParam('api')) {
        ft.app.module.baseApiUrl = ft.getQueryParam('api');
    }
}

ft.app.module.getTemplateUrl = function (urlPart) {
    return ft.app.module.baseTemplateUrl + urlPart + '.html?v=' + ft.randomNumber;
};

ft.app.module.config(
    ['$compileProvider', '$stateProvider', '$urlRouterProvider', '$sceDelegateProvider', '$translateProvider', 'ftConfigurationProvider',
        function ($compileProvider, $stateProvider, $urlRouterProvider, $sceDelegateProvider, $translateProvider, ftConfigurationProvider) {

            if (!ft.DEBUG) {
                $compileProvider.debugInfoEnabled(false);
                $compileProvider.commentDirectivesEnabled(false);
            }

            // default settable stuff
            ftConfigurationProvider.setTokenUrl('account/token');
            ftConfigurationProvider.setRefreshTokenUrl('account/token');
            ftConfigurationProvider.setCurrentUserUrl('account/validate');
            ftConfigurationProvider.setBaseApiUrl(ft.app.module.baseApiUrl);
            ftConfigurationProvider.setSplashViewUrl(ft.app.module.getTemplateUrl('app/splash'));

            // everything else that comes in handy
            ftConfigurationProvider.configure({
                appTitle: 'TTE Screen'
            });

            $sceDelegateProvider.resourceUrlWhitelist([
                // Allow same origin resource loads.
                'self',
                // Allow loading from our assets domain.  Notice the difference between * and **.
                'http://*.local/**',
                'http://ft.components.local/**',
                'http://ft.layout.local/**',
                'https://*.local/**',
                'http://*.42windmills.com/**',
                'https://*.42windmills.com/**',
                'https://media.kindplanner.nl/**',
                'https://media-*.kindplanner.nl/**'
            ]);

            var translateConfig = {
                files: ft.components.module.getTranslationFiles()
            };

            $translateProvider
                .useStaticFilesLoader(translateConfig)
                .preferredLanguage('en');

            var initializeApp = {
                name: 'outside-app.initialize',
                url: '/',
                views: {
                    'content': {
                        controller: 'tteInitializeController',
                        templateUrl: ft.app.module.getTemplateUrl('app/initialize')
                    }
                }
            };

            var tteSetup = {
                name: 'outside-app.setup',
                url: '/setup',
                pageStyle: 'kp-setup',
                views: {
                    'content': {
                        controller: 'tteSetupController',
                        templateUrl: ft.app.module.getTemplateUrl('app/setup')
                    }
                }
            };

            var tteHome = {
                name: 'outside-app.home',
                url: '/home',
                pageStyle: 'kp-home',
                layout: 'full-width',
                views: {
                    'content': {
                        controller: 'tteHome',
                        templateUrl: ft.app.module.getTemplateUrl('home/main')
                    }
                }
            };

            var tteAlbum = {
                name: 'outside-app.album',
                url: '/album',
                pageStyle: 'kp-album',
                layout: 'full-width',
                views: {
                    'content': {
                        controller: 'tteAlbumController',
                        templateUrl: ft.app.module.getTemplateUrl('album/main')
                    }
                },
                params: {
                    album: null
                }
            };

            var activities = {
                name: 'outside-app.activities',
                url: '/activities',
                pageStyle: 'kp-activities-list',
                layout: 'full-width',
                views: {
                    'content': {
                        controller: 'tteActivitiesController',
                        templateUrl: ft.app.module.getTemplateUrl('activities/list')
                    }
                }
            };
            
            const readonlyActivities = {
                name: 'outside-app.readonly-activities',
                url: '/activities/readonly',
                pageStyle: 'kp-activities-list',
                layout: 'full-width',
                views: {
                    'content': {
                        controller: 'tteActivitiesController',
                        templateUrl: ft.app.module.getTemplateUrl('activities/readonly-list')
                    }
                }
            };

            var tteNews = {
                name: 'outside-app.news',
                url: '/news',
                pageStyle: 'kp-news',
                layout: 'full-width',
                views: {
                    'content': {
                        controller: 'tteNewsController',
                        templateUrl: ft.app.module.getTemplateUrl('news/main')
                    }
                },
                params: {
                    articles: null,
                }
            };

            //#endregion
            $stateProvider.state(initializeApp);
            $stateProvider.state(tteSetup);
            $stateProvider.state(activities);
            $stateProvider.state(readonlyActivities);
            $stateProvider.state(tteAlbum);
            $stateProvider.state(tteHome);
            $stateProvider.state(tteNews);
            $urlRouterProvider.otherwise('/');
        }
    ]);

ft.app.module.run();

window.appModels = ft.app.models;
window.appEvents = ft.app.events;
window.appSettings = ft.app.settings;
