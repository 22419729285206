import { DataService, KioskMode } from './ft.app.services.data';
import moment from 'moment';

export type Action = () => void;

export class AppConfigurationService {
    public static $inject = ['ftAppDataService'];
    private readonly dataService: DataService;

    private lastFetched: moment.Moment;
    private lastFetchedMode: KioskMode;

    constructor(dataService: DataService) {
        this.dataService = dataService;
        this.getKioskMode = this.getKioskMode.bind(this);
    }

    public async getKioskMode(): Promise<KioskMode> {
        if (this.lastFetchedMode != null && this.lastFetched != null && moment().diff(this.lastFetched, 'minutes') < 5) {
            return this.lastFetchedMode;
        }

        try {
            const response = await this.dataService.getKioskMode();
            if (response?.mode != null) {
                this.lastFetchedMode = response.mode;
                this.lastFetched = moment();
            }

            return response.mode;
        } catch (e) {
            return 'normal';
        }
    }
}

window.ft.app.module.service('appConfigurationService', AppConfigurationService);