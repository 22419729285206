import { DataService } from '@/services/ft.app.services.data';
import { NavigationService } from '@/services/navigation-service';
import { RFIDService } from '@/services/rfid-service';

window.ft.app.module.controller('ftAppController',
	['$rootScope', '$scope', '$state', '$stateParams', 'ftLoginService', 'ftSettingService', 'ftAppDataService', '$localStorage', '$interval', 'rfidService', 'navigationService',
		function ($rootScope, $scope, $state, $stateParams, ftLoginService, ftSettingService, DataService: DataService, $localStorage, $interval, rfidSerivce: RFIDService, navigationService: NavigationService) {

			let refreshTokenInterval: number;

			$scope.isLoading = false;
			$scope.showSplash = true;
			$scope.pageStyle = null;

            var getUserByRFID = async function (rfidtag: string) {
				$rootScope.isLoading = true;

				try {
					const response = await DataService.getUserByRFIDTag(rfidtag);
					ftSettingService.set(window.appKeys.user, response);

					if (response.type == window.ft.userTypes.child && response.firstName) {	
						navigationService.navigateToActivities();
					} else {
						$rootScope.$broadcast(window.ft.appEvents.enableRFID);
					}
				} finally {
					$rootScope.isLoading = false;
				}
			};

            var refreshToken = async function () {
				$rootScope.isLoading = true;

				try {
					const response = await DataService.renewToken(ftLoginService.getTokenData().refreshToken);
					ftLoginService.storeTokenData(response);
					startRefreshTokenInterval();					
				} finally {
					$scope.isLoading = false;
				}
			};

            var startRefreshTokenInterval = function () {
                $interval.cancel(refreshTokenInterval);
                refreshTokenInterval = $interval(function () {
                    console.log('Refreshing token, expires at ' + new Date(ftLoginService.getTokenData().token.expires).toUTCString() + ' and it\'s now ' + new Date().toUTCString());
                    refreshToken();
                }, getTokenRefreshSpeed());
			};

			var getTokenRefreshSpeed = function () {

				if ($localStorage.tokenRefreshSpeed) {
					return $localStorage.tokenRefreshSpeed;
				}

				// Get the expire date and subtract 10 seconds from it
				var expireDate = new Date(ftLoginService.getTokenData().token.expires);
				expireDate.setSeconds(expireDate.getSeconds() - 10);

				var now = new Date().toUTCString();

                // Get time difference in milliseconds
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
				var refreshSpeed = new Date(expireDate.toUTCString()) - new Date(now);
				$localStorage.tokenRefreshSpeed = refreshSpeed;

				return refreshSpeed;
			};

			var initializeEvents = function () {
				const offStateChangeSuccess = $rootScope.$on('$stateChangeSuccess', handleStateChangeSuccess);
				const offRefreshToken = $rootScope.$on(window.ft.appEvents.tokenRefresh, startRefreshTokenInterval);
				const offIsLoading = $rootScope.$on(window.ft.appEvents.isLoading, (_evt, result: boolean) => $scope.showSplash = result);
                const offRenewToken = $rootScope.$on(window.ft.appEvents.renewToken, refreshToken);

                const unsubscribe = rfidSerivce.onTag(tag => {
					$rootScope.isLoading = true;
					getUserByRFID(tag);
				});
				
                $scope.$on('$destroy', () => {
					offStateChangeSuccess();
					offRefreshToken();
					offIsLoading();
					offRenewToken();
					unsubscribe();
				});
			};

			var handleStateChangeSuccess = function () {
				// only save current state after login (when in-app)
				if ($state.current.name.indexOf('app') == 0) {
					ftSettingService.set(window.ft.framework.module.settings.keys.currentState, [$state.current.name, $stateParams]);
				}

				$scope.pageStyle = $state.current.pageStyle || null;
			};

			initializeEvents();
		}
	]);